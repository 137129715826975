// custom typefaces
import './src/styles/bootstrap.css';
import "./src/styles/fonts.css"
import "./src/styles/style.css"
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';

config.autoAddCss = false;


